import React from 'react';
import NextLink from 'next/link';
import { makeStyles } from '@mui/styles';
import { Box, Container, Grid, Typography, Link } from '@mui/material';
import BaseLayout from 'layouts/BaseLayout';
import { getEnv, openIntercomChat } from 'utils';
import { COLOR_PURPLE_HOVER, COLOR_WHITE } from 'styles/variables/colors';
import useIsMobile from 'hooks/useIsMobile';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),

    [theme.breakpoints.up(1400)]: {
      paddingTop: theme.spacing(24),
      paddingBottom: theme.spacing(24),
    },
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    margin: '0 auto',
  },
  title: {
    color: '#7833FF',
    fontSize: '1.75rem',
  },
  textGrid: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    display: 'inline-block',
    marginBottom: theme.spacing(1),
    color: '#7833FF',
  },
}));

export default function NotFoundPage() {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <BaseLayout
      bgBrandColor={!isMobile ? COLOR_WHITE : COLOR_PURPLE_HOVER}
      headerWhite={true}
      typeBtn="buttonSecondary"
      hasPadding={false}
    >
      <Container maxWidth="md">
        <Box className={classes.wrapper}>
          <Grid container={true} spacing={2} alignItems="center">
            <Grid item={true} xs={12} md={6}>
              <img className={classes.image} src="/images/not-found.svg" alt="Not found page" />
            </Grid>
            <Grid className={classes.textGrid} item={true} xs={12} md={6}>
              <Typography className={classes.title}>
                <b>A Synapse Problem Occurred</b>
              </Typography>

              <Typography>
                Unfortunately, we are unable to communicate with the page
                <br />
                you are looking for. If you need help, contact us{' '}
                <Link className={classes.link} onClick={openIntercomChat} underline="hover">
                  here
                </Link>
                .
              </Typography>

              <Typography>
                <b>Error code: 404</b>
              </Typography>

              <Typography>Here are a few links that may be helpful:</Typography>

              <div>
                <div>
                  <Link className={classes.link} href={getEnv('MTG_URL_APP')} underline="hover">
                    Home
                  </Link>
                </div>
                <div>
                  <NextLink href="/illustrations" passHref={true}>
                    <Link className={classes.link} underline="hover">
                      Gallery
                    </Link>
                  </NextLink>
                </div>
                <div>
                  <NextLink href="/templates" passHref={true}>
                    <Link className={classes.link} underline="hover">
                      Templates
                    </Link>
                  </NextLink>
                </div>
                <div>
                  <Link
                    className={classes.link}
                    href={`${getEnv('MTG_URL_APP')}/pricing`}
                    underline="hover"
                  >
                    Pricing
                  </Link>
                </div>
                <div>
                  <Link
                    className={classes.link}
                    href={`${getEnv('MTG_URL_APP')}/blog`}
                    underline="hover"
                  >
                    Blog
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </BaseLayout>
  );
}
