import { EditageConfig } from 'config/onelogin.config';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthUser } from 'features/auth/AuthUserContext';

const DEFAULT_GEO_DATA = {
  ip: '127.0.0.1',
  country_code: 'US',
  country_name: 'United States',
  region_code: 'NY',
  region_name: 'New York',
  city: 'New York',
  zip_code: '10001',
  time_zone: 'America/New_York',
  latitude: 40.7128,
  longitude: -74.006,
  metro_code: 0,
};

/**
 * Custom hook to fetch and manage geographical data based on the user's IP address.
 *
 * This hook fetches geo data from a specified endpoint and caches it in localStorage.
 * It also integrates with the authentication context to ensure data is fetched only
 * when the user is not loading.
 *
 * @returns {Object} An object containing the following properties:
 * - geoData {Object}: The geographical data including IP, country, region, city, etc.
 * - setGeoData {Function}: Function to manually set the geo data.
 * - isLoadingGeoData {boolean}: Boolean indicating if the geo data is currently being loaded.
 *
 * @example
 * const { geoData, setGeoData, isLoadingGeoData } = useGeoData();
 *
 * @typedef {Object} GeoData
 * @property {string} ip - The IP address of the user.
 * @property {string} country_code - The country code of the user's location.
 * @property {string} country_name - The country name of the user's location.
 * @property {string} region_code - The region code of the user's location.
 * @property {string} region_name - The region name of the user's location.
 * @property {string} city - The city of the user's location.
 * @property {string} zip_code - The zip code of the user's location.
 * @property {string} time_zone - The time zone of the user's location.
 * @property {number} latitude - The latitude of the user's location.
 * @property {number} longitude - The longitude of the user's location.
 * @property {number} metro_code - The metro code of the user's location.
 */
export default function useGeoData() {
  const [geoData, setGeoData] = useState(DEFAULT_GEO_DATA);
  const { isLoading: isLoadingUser } = useAuthUser();
  const [isLoadingGeoData, setIsLoadingGeoData] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoadingGeoData(true);

      try {
        const cachedGeoData = localStorage.getItem('geoData');

        if (cachedGeoData) {
          setGeoData(JSON.parse(cachedGeoData));
        } else {
          const geoUrl = `${EditageConfig.geoIp.endpoint}`;
          const { data } = await axios.get(geoUrl);

          setGeoData(data);
          localStorage.setItem('geoData', JSON.stringify(data));
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoadingGeoData(false);
      }
    }

    if (!isLoadingUser) {
      fetchData();
    }
  }, [isLoadingUser]);

  return { geoData, setGeoData, isLoadingGeoData };
}
