export default {
  gallery: 'Galerie',
  myCreations: 'Mes créations',
  infographicTemplates: "Modèles d'infographies",
  pricing: 'Tarification',
  institutions: 'Institutions',
  customServices: 'Design sur mesure',
  aboutUs: "A propos d'AAC",
  blog: 'Blog',
};
