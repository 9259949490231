import { useEffect, useState } from 'react';
import useHasMounted from './useHasMounted';
import { BREAKPOINT_DESKTOP } from 'styles/variables/breakpoints';

export default function useIsMobile(breakpoint) {
  const hasMounted = useHasMounted();
  const [isMobile, setIsMobile] = useState(false);

  function handleWindowResize() {
    setIsMobile(window.innerWidth < (breakpoint || BREAKPOINT_DESKTOP));
  }

  useEffect(() => {
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return hasMounted && isMobile;
}
