import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import PageHeader from 'components/PageHeader';
import BrandBackground from 'components/BrandBackground';
import routes from 'config/routes';
import useIsMobile from 'hooks/useIsMobile';
import { isAnniversaryActive } from 'utils';
import { COLOR_WHITE } from 'styles/variables/colors';
import { useRouter } from 'next/router';
import NavBarOneResearcher from 'components/NavBarOneResearcher';
import PricingBanner from 'components/PricingBanner';
const DynamicPageFooter = dynamic(() => import('components/PageFooter'));
const DynamicPageFooterMobile = dynamic(() => import('components/PageFooterMobile'));
const DynamicPageMain = dynamic(() => import('components/PageMain'));
const DynamicCtaModalEmailForm = dynamic(() => import('components/CtaModalEmailForm'));
const PageFooterCustom = dynamic(() => import('components/PageFooterCustom'));

const BlackNovemberCountdownBanner = dynamic(
  () => import('components/BlackNovember/CountdownBanner'),
  {
    ssr: false,
  },
);

const DynamicCountdownBanner = dynamic(() => import('components/Anniversary/CountDownBanner'), {
  ssr: false,
});

const Props = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.node,
  section: PropTypes.node,
  hasPadding: PropTypes.bool,
  FooterProps: PropTypes.shape({
    socialLinks: PropTypes.bool,
  }),
  hamburgerMenuActive: PropTypes.bool,
  headerWhite: PropTypes.bool,
  context: PropTypes.string,
};

const DefaultProps = {
  hero: null,
  section: null,
  hasPadding: true,
  FooterProps: {},
};

const b2cRoutes = ['/teams-and-labs', '/institutions', '/partners'];

export default function BaseLayout({
  children,
  hero,
  section,
  hasPadding,
  FooterProps,
  bgBrandColor,
  typeBtn,
  scrolled,
  hamburgerMenuActive,
  headerWhite,
  context,
  userCountry,
}) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const isMobile = useIsMobile();
  const router = useRouter();
  const isHomePage = router.pathname === '/';
  const isB2cRoute = !!b2cRoutes.some((route) => router.pathname.includes(route));

  const logos = {
    white: { editage: '/images/mtg-logo-editage.svg', default: '/images/mtg-logo.svg' },
    red: { editage: '/images/mtg-logo-red-editage.svg', default: '/images/mtg-logo-red.svg' },
  };

  const activeLogoType = isHomePage || !isB2cRoute ? 'editage' : 'default';

  return (
    <>
      <PricingBanner />

      <NavBarOneResearcher />

      <BrandBackground bgBrandColor={bgBrandColor}>
        <PageHeader
          bgBrandColor={bgBrandColor}
          brandSrc={
            bgBrandColor !== COLOR_WHITE ? logos.white[activeLogoType] : logos.red[activeLogoType]
          }
          brandType={activeLogoType}
          brandAlt="Mind the Graph"
          brandHref="/"
          menuItems={routes()}
          isNavOpen={isNavOpen}
          onNavClose={() => setIsNavOpen(false)}
          onNavOpen={() => setIsNavOpen(true)}
          typeBtn={typeBtn}
          scrolled={scrolled}
          hamburgerMenuActive={hamburgerMenuActive}
          headerWhite={headerWhite}
        />

        <BlackNovemberCountdownBanner />

        {isAnniversaryActive() && isHomePage ? <DynamicCountdownBanner /> : null}

        {hero}
      </BrandBackground>

      <DynamicCtaModalEmailForm context={context} />

      <DynamicPageMain hasPadding={hasPadding}>{children}</DynamicPageMain>

      {section}

      {['JP', 'CN'].includes(userCountry) ? (
        <PageFooterCustom />
      ) : !isMobile ? (
        <DynamicPageFooter {...FooterProps} />
      ) : (
        <DynamicPageFooterMobile {...FooterProps} />
      )}
    </>
  );
}

BaseLayout.propTypes = Props;
BaseLayout.defaultProps = DefaultProps;
