import ApiService from './ApiService';

export default class PaymentService {
  data() {
    return ApiService.get('/payments/data');
  }

  paypal(data) {
    return ApiService.post('/payments/paypal', data);
  }

  stripe(data) {
    return ApiService.post('/payments/stripe', data);
  }

  stripeTrial(data) {
    return ApiService.post('/payments/stripe/trial-subscription', data);
  }

  createOneTimeSubscription(data) {
    return ApiService.post('/payments/stripe/one-time', data);
  }

  createStripeSetupIntentBasic() {
    return ApiService.post('/payments/stripe/setup-intent-basic');
  }

  createStripeSetupIntent(data) {
    return ApiService.post('/payments/stripe/setup-intent', data);
  }

  getStripeSetupIntent(data) {
    return ApiService.get(`/payments/stripe/setup-intent/${data}`);
  }

  attachStripePaymentMethod(data) {
    return ApiService.post('/payments/stripe/attach-payment-method', data);
  }

  updateStripePaymentMethod(data) {
    return ApiService.put('/payments/stripe/payment-method', data);
  }

  getStripeSubscription() {
    return ApiService.get(`/payments/stripe/subscription`);
  }

  getStripePaymentMethods() {
    return ApiService.get(`/payments/stripe/payment-method`);
  }

  deleteStripePaymentMethod(data) {
    return ApiService.delete(`/payments/stripe/payment-method/${data}`);
  }

  chargeStripeInvoice(data) {
    return ApiService.post('/payments/stripe/charge-invoice', data);
  }

  updateStripeSubscription(data) {
    return ApiService.put('/payments/stripe/subscription', data);
  }

  createPaymentInfo(data) {
    return ApiService.post('/payment-infos', data);
  }

  getPaymentInfoById(id) {
    return ApiService.get(`payment-infos/${id}`);
  }
}
