import PaymentService from 'services/PaymentService';

export const DEFAULT_LOCALE_ID = 'US';

export function setLocale(locale, translations) {
  if (translations[locale]) {
    return translations[locale];
  } else {
    return {};
  }
}

export const checkUserCountry = {
  isChina: async (user) => {
    return await checkCountry(user, 'CN');
  },
  isJapan: async (user) => {
    return await checkCountry(user, 'JP');
  },
  isBrazil: async (user) => {
    return await checkCountry(user, 'BR');
  },
  isIndia: async (user) => {
    return await checkCountry(user, 'IN');
  },
  getUserCountry: async (user) => {
    if (typeof window === 'undefined') {
      return null;
    }

    if (user) {
      localStorage.setItem('countryFromUser', user.country_code);
      return user.country_code;
    }

    if (localStorage.getItem('countryFromUser')) {
      return localStorage.getItem('countryFromUser');
    }

    const paymentService = new PaymentService();
    const { data } = await paymentService.data();

    if (typeof window !== 'undefined') {
      localStorage.setItem('countryFromUser', data.countryCode);
    }

    return data.countryCode;
  },
};

async function checkCountry(user, countryCode) {
  if (typeof window === 'undefined') {
    return null;
  }

  const paymentService = new PaymentService();

  if (user) {
    const isCountry = user.country_code === countryCode;
    localStorage.setItem('countryFromUser', user.country_code);
    return isCountry;
  }

  if (localStorage.getItem('countryFromUser')) {
    return localStorage.getItem('countryFromUser') === countryCode;
  }

  const { data } = await paymentService.data();
  const isCountry = data.countryCode === countryCode;

  if (typeof window !== 'undefined') {
    localStorage.setItem('countryFromUser', data.countryCode);
  }

  return isCountry;
}

/**
 * Get the currency symbol based on the currency code
 * @param {string} currencyCode example (USD, BRL, INR)
 * @returns {string} currencySymbol example (US$, R$, ₹)
 */
export function getCurrencySymbol(currencyCode) {
  const currencySymbols = {
    USD: 'US$',
    BRL: 'R$',
    INR: '₹',
  };

  return currencySymbols[currencyCode] || currencyCode;
}
