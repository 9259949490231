import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 13,
    fontWeight: 700,
    color: theme.palette.common.white,
  },
  link: {
    color: theme.palette.common.white,
    fontSize: 13,
    fontWeight: 900,
    textDecorationColor: theme.palette.common.white,
  },
  redDot: {
    width: '8px',
    height: '8px',
    backgroundColor: '#FF0066',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px',
  },
}));
