import { Box, Button, Link, Typography } from '@mui/material';
import { Icons } from 'components/Icons';
import useGeoData from 'hooks/useGeoData';
import { useRouter } from 'next/router';
import { COLOR_PINK, COLOR_PURPLE } from 'styles/variables/colors';
import { useStyles } from './styles';
import useIsMobile from 'hooks/useIsMobile';
import getCleverTap from 'utils/clevertap';
import { useAuthUser } from 'features/auth/AuthUserContext';
import { geoDiscountConfig } from 'config/discount';

const ALLOWED_PATH_PREFIXES = [
  '/pricing',
  '/',
  '/illustrations',
  '/infographic-templates',
  '/pricing',
  '/anniversary',
];

export default function PricingBanner() {
  const { user } = useAuthUser();
  const router = useRouter();
  const { pathname, asPath } = router;
  const classes = useStyles();
  const { geoData } = useGeoData();
  const isMobile = useIsMobile();
  const clevertap = getCleverTap();

  const isAllowedPath = ALLOWED_PATH_PREFIXES.some(
    (prefix) => pathname === prefix || pathname.startsWith(`${prefix}/`),
  );

  const countryCode = geoData?.country_code;
  const config = countryCode && (geoDiscountConfig[countryCode] || geoDiscountConfig.US);

  if (isMobile || !config || !isAllowedPath) {
    return null;
  }

  function getLinkDestination() {
    clevertap.event.push('Banner click', {
      Product: 'MTG',
      Email: user?.email || '',
      URL: asPath,
      Country: countryCode,
      Description: config.description,
      Locale: countryCode,
    });

    const currentUrlWithoutHash = asPath.split('#')[0];

    const hashMap = {
      '/pricing': '#plans-list',
    };

    const queryString = new URLSearchParams(router.query).toString();

    const hash = hashMap[pathname];
    return hash ? `${currentUrlWithoutHash}${hash}` : `/pricing?${queryString}`;
  }

  function handleWebinarRegister() {
    // Add tracking for webinar registration if needed
    clevertap.event.push('Banner click', {
      Product: 'MTG',
      Email: user?.email || '',
      URL: asPath,
      Country: countryCode,
      Locale: countryCode,
      Description: 'Webinar registration',
    });

    // Navigate to webinar registration page
    // This is a placeholder - replace with actual registration URL
    window.open(
      'https://www.airmeet.com/e/bf71f4b0-04ba-11f0-9772-776d3b596257?utm_source=MTG_Website&utm_medium=Banner&utm_campaign=MTG_BRL_Webinar_100425',
      '_blank',
    );
  }

  return countryCode === 'BR' ? (
    <Box display="flex" width="100%" height="90px">
      {/* Webinar Section - Purple */}
      <Box
        bgcolor={COLOR_PURPLE}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pl={0}
        pr={4}
        width="50%"
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Box
            sx={{
              bgcolor: '#1E294D',
              height: '40px',
              borderRadius: '0 50px 50px 0',
              pl: 2,
              pr: 2,
              py: 0.5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span className={classes.redDot}></span>
            <Typography variant="caption" color="white" fontWeight="bold">
              FREE WEBINAR
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" color="white" fontWeight="bold">
              Ilustrações Científicas para Publicações de Alto Impacto
            </Typography>
            <Typography variant="body2" color="white">
              10 de abril de 2025, 10h (Horário de Brasília)
            </Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          sx={{
            bgcolor: 'white',
            color: '#7833FF',
            fontSize: '14px',
            borderRadius: '50px',
            fontWeight: '700',
            textTransform: 'none',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.95)',
            },
            px: 3,
          }}
          onClick={handleWebinarRegister}
        >
          Register Now
        </Button>
      </Box>

      {/* Discount Section - Pink */}
      <Box
        bgcolor={COLOR_PINK}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={4}
        width="50%"
      >
        <Box display="flex" gap={1.5} alignItems="center">
          <Icons.offer color="#FFF" />
          <Typography variant="body2" className={classes.text}>
            Celebrate our 11th anniversary and boost your science communication! - Up to{' '}
            {config.discount}% OFF discount.
          </Typography>
        </Box>
        <Link href={getLinkDestination()} className={classes.link}>
          Claim Your Discount Now
        </Link>
      </Box>
    </Box>
  ) : (
    /* All except BR */
    <Box
      bgcolor={COLOR_PINK}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
      py={2}
      px={10}
    >
      <Box display="flex" gap={1.5} alignItems="center">
        <Icons.offer color="#FFF" />
        <Typography variant="body2" className={classes.text}>
          Celebrate our 11th anniversary and boost your science communication! - Up to{' '}
          {config.discount}% OFF discount.
        </Typography>
      </Box>
      <Link href={getLinkDestination()} className={classes.link}>
        Claim Your Discount Now
      </Link>
    </Box>
  );
}
