export default {
  gallery: 'Gallery',
  myCreations: 'MisCreaciones',
  infographicTemplates: 'Plantillas Infográficas',
  pricing: 'Precios',
  institutions: 'Instituciones',
  customServices: 'Diseño personalizado',
  aboutUs: 'SobreNosotros',
  blog: 'Blog',
};
