import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { Box } from '@mui/material';

const Props = {
  children: PropTypes.node.isRequired,
  bgBrandColor: PropTypes.string,
};

export default function BrandBackground({ children, bgBrandColor }) {
  return (
    <>
      {bgBrandColor ? (
        <Box bgcolor={bgBrandColor}>{children}</Box>
      ) : (
        <Container>{children}</Container>
      )}
    </>
  );
}

BrandBackground.propTypes = Props;
