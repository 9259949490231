import { flowTrialActive, getEnv } from 'utils';
import { setLocale } from 'utils/locale';
import { useRouter } from 'next/router';

import en from './locales/en';
import ja from './locales/en';
import zh from './locales/en';
import es from './locales/es';
import fr from './locales/fr';
import it from './locales/it';

const routes = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const router = useRouter();
  const { locale } = router;
  const isIllustrationsPage = router.asPath.includes('/illustrations');
  const t = isIllustrationsPage
    ? setLocale(locale, { en, es, fr, it, ja, zh })
    : setLocale('en', { en });

  const routes = [
    {
      path: '/my-creations',
      label: t.myCreations,
      isLogged: true,
    },
    {
      path: '/illustrations',
      label: t.gallery,
    },
    {
      path: '/infographic-templates',
      label: t.infographicTemplates,
    },
    {
      path: flowTrialActive ? '/pricing-alt' : '/pricing',
      label: t.pricing,
    },
    {
      path: '/institutions',
      label: t.institutions,
    },
    {
      path: '/custom-services',
      label: t.customServices,
    },
    {
      path: '/about-us',
      label: t.aboutUs,
    },
    {
      path: getEnv('MTG_URL_APP') + '/blog',
      label: t.blog,
      isExternal: true,
      isBlank: true,
    },
  ];

  return routes;
};

export default routes;
