export default {
  gallery: 'Galleria',
  myCreations: 'Le mie creazioni',
  infographicTemplates: 'Modelli di infografica',
  pricing: 'Prezzi',
  institutions: 'Istituzioni',
  customServices: 'Design personalizzato',
  aboutUs: 'Chi siamo',
  blog: 'Blog',
};
