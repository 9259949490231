export default {
  gallery: 'Gallery',
  myCreations: 'My Creations',
  infographicTemplates: 'Infographic Templates',
  pricing: 'Pricing',
  institutions: 'Institutions',
  customServices: 'Custom Design',
  aboutUs: 'About Us',
  blog: 'Blog',
};
